import { useState } from 'react'

import { Modal, Input, Row, Spacer, Button } from '@geist-ui/react'
import { Plus, Mail } from '@geist-ui/react-icons'

import firebase from 'firebase/app'

import { useShowToast } from 'utils/hooks'
 
function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const InputRow = ({ value, onChange }) => (
  <>
    <Input icon={<Mail />} value={value} onChange={event => onChange(event.target.value)} placeholder="name@example.com" type="email" name="email" width="100%" />
    <Spacer />
  </>
)

export default function InviteModal({ doc, ...bindings }) {
  const showToast = useShowToast()
  const [emails, setEmails] = useState(['', '', ''])

  const addEmail = () => setEmails(state => [...state, ''])
  const onChangeEmail = (email, index) => setEmails(state => {
    const nextState = [...state]
    nextState[index] = email
    return nextState
  })

  const onSubmit = () => {
    const validEmails = emails.filter(validateEmail)
    doc.ref.update('invites', firebase.firestore.FieldValue.arrayUnion(...validEmails))

    showToast({ text: 'Invites sent. They have been notified with email to join a workspace.', type: 'success' })
    bindings.onClose()
    setEmails(['', '', ''])
  }

  return (
    <Modal {...bindings}>
      <Modal.Title>Invite members</Modal.Title>
      <Modal.Subtitle>Invite members to collaborate in Bakcdated:</Modal.Subtitle>
      <Modal.Content>
        {emails.map((email, index) => <InputRow key={index} value={email} onChange={email => onChangeEmail(email, index)} />)}

        <Row>
          <Button auto size="mini" icon={<Plus />} onClick={addEmail}>Add more</Button>
        </Row>
      </Modal.Content>
      <Modal.Action passive onClick={bindings.onClose}>Cancel</Modal.Action>
      <Modal.Action onClick={onSubmit}>Confirm</Modal.Action>
    </Modal>
  )
}