import { Card, Text, Spacer, Row , Button } from '@geist-ui/react'
import { XCircle } from '@geist-ui/react-icons'

export default function Announcement({ title, description, image, onClose }) {
  return (
    <>
      <Spacer />
      <Card type="violet">
        <Row justify="space-between">
          <Text h4>{title}</Text>
          <XCircle className="cursor-pointer" onClick={onClose} />
        </Row>
        <Text>{description}</Text>
      </Card>
      <Spacer />
    </>
  )
}