import { useCallback } from 'react'
import { useRouter } from 'next/router'
import { useTheme } from '@geist-ui/react'

import { Tabs } from '@geist-ui/react'


export default function PageTabs({ children }) {
  const { palette }  = useTheme()
  const router = useRouter()

  const { query: { tab = '' } } = router

  const onTabChange = useCallback(value => {
    const { query, pathname } = router
    const nextQuery = { ...query, tab: value }

    if (!value) delete nextQuery.tab

    router.push({ pathname, query: nextQuery })
  }, [router])

  return (
    <>
      <nav className="main-tabs sticky top-0 z-10">
        <Tabs value={tab} onChange={onTabChange}>
          {children}
        </Tabs>
      </nav>

      <style jsx>{`
        .main-tabs :global(.tabs) > :global(header) {
          background-color: ${palette.background};
        }

        .main-tabs :global(.tabs) :global(.tab) {
          height: 52px;
        }
      `}</style>
    </>
  )
}

PageTabs.Item = Tabs.Item