import { useRouter } from 'next/router'
import Head from 'next/head'

import { Text, Button, Spacer, Card, Tag } from '@geist-ui/react'
import { Link as GeistLink, Row, Grid, Popover, Table, Display, Image, useModal } from '@geist-ui/react'
import { MoreVertical } from '@geist-ui/react-icons'

import firebase from 'firebase/app'

import Link from 'components/Link'
import Tabs from 'components/Tabs'
import Announcement from 'components/Announcement'

import InviteModal from 'components/modals/Invite'

import { useWorkspaceBoardsCollection, userSharedRetrosCollection, useYourWorkspaceBoardsCollection, useQueryTab, useCurrentWorkspaceDocument, useLocalStorage, useCurrentUserShape, useBoolean } from 'utils/hooks'
import { useIsPersonalWorkspace, useCurrentWorkspaceId, useIsDocAdmin, useIsCurrentUserIn } from 'utils/hooks'

const CARD_DESCRIPTION_LINE_COUNT = 2
const CARD_DESCRIPTION_LINE_HEIGHT = 26

const yourWorkspaceBoards = ''
const publicBoards = 'public'
const sharedBoards = 'shared'

const EmptyTab = ({ onCreateClick }) => {
  const caption = onCreateClick ?
    <p>That's all. <GeistLink href="/#" color onClick={event => {
      event.preventDefault()
      event.stopPropagation()
      onCreateClick()
    }}>Create</GeistLink> your first baord 💪</p> :
    <p>That's all. Ask for an invite link to join others board</p>

  return (
    <Display caption={caption}>
      <Image width={540} style={{ objectFit: 'contain' }} src="/images/drawkit-content-man-colour.svg" />
    </Display>
  )
}

const SharedBoards = ({ snapshot }) => {
  if (snapshot.empty) return <EmptyTab />

  return (
    <>
      <Spacer />
      <Table
        hover={false}
        data={snapshot.docs.map(doc => {
          const { title, description } = doc.data()
          const link = <Link href={`~/board?doc=${doc.id}`} color icon>Open</Link>
          return ({ doc, title, description, link })
        })}
      >
        <Table.Column prop="title" label="title" width={250} />
        <Table.Column prop="description" label="description" />
        <Table.Column prop="link" label="open board" width={150} />
      </Table>
    </>
  )
}

const WorkspaceCard = ({ doc }) => {
  const { id } = doc
  const { title, description, visibility, participants } = doc.data()

  const [processingJoin, onJoinProcess, offJoinProcess] = useBoolean(false)

  const userShape = useCurrentUserShape()

  const isBoardAdmin = useIsDocAdmin(doc)
  const isParticipant = useIsCurrentUserIn(participants)
  const isPublic = visibility === 'public'

  const onDeleteClick = () => doc.ref.delete()
  const onJoinClick = async () => {
    onJoinProcess()

    await doc.ref.update({
      participants: firebase.firestore.FieldValue.arrayUnion(userShape.uid),
      [`roles.${userShape.uid}`]: 'member',
      [`users.${userShape.uid}`]: userShape,
    })

    offJoinProcess()
  }

  return (
    <>
      <Card>
        <Row justify="space-between" align="middle">
          <div className="flex items-center space-x-2">
            <Text size={20} span b>
              <div className="card-title-content">{title}</div>
            </Text>
            {isPublic && <Tag type="lite">Public</Tag>}
          </div>
          {isBoardAdmin && (
              <Popover
                content={(
                  <>
                    <Popover.Item onClick={onDeleteClick}>
                      <span>Delete</span>
                    </Popover.Item>
                  </>
                )}
              >
              <MoreVertical />
            </Popover>
          )}
        </Row>
        <Text>
          <span className="card-text-content">{description}</span>
        </Text>
        <Card.Footer>
          {isParticipant && !processingJoin ? (
            <Link href={`~/board?doc=${id}`} color icon>Go to board</Link>
          ) : (
            <Button size="mini" onClick={onJoinClick} loading={processingJoin}>Join public board</Button>
          )}
        </Card.Footer>
      </Card>

      <style jsx>{`
        .card-text-content {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: ${CARD_DESCRIPTION_LINE_COUNT};
          -webkit-box-orient: vertical;
          height: ${CARD_DESCRIPTION_LINE_COUNT * CARD_DESCRIPTION_LINE_HEIGHT}px;
        }
        .card-title-content {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      `}</style>
    </>
  )
}


const WorkspaceBoards = ({ snapshot, workspaceDoc }) => {
  const isPersonalWorkspace = useIsPersonalWorkspace()
  const worksapceId = useCurrentWorkspaceId()
  const router = useRouter()

  const isWorkspaceAdmin = useIsDocAdmin(workspaceDoc)

  const { setVisible: setVisibleInvite, bindings: bindingsInvite } = useModal(false)

  const onInviteTeamClick = () => {
    if (isPersonalWorkspace) {
      router.push(`/create-workspace`)
    } else {
      setVisibleInvite(true)
    }
  }

  const onNewBoarClick = () => {
    if (isPersonalWorkspace) {
      router.push(`/run-tool`)
    } else {
      router.push(`/w/${worksapceId}/run-tool`)
    }
  }

  let content = null

  if (snapshot.empty) {
    content = <EmptyTab onCreateClick={() => setVisible(true)} />
  } else {
    content = (
      <>
        <Grid.Container gap={2} justify="flex-start">
          {snapshot.docs.map(doc =>
             <Grid key={doc.id} md={8} sm={12} xs={24}>
              <WorkspaceCard doc={doc} />
            </Grid>
          )}
        </Grid.Container>  
      </>
    )
  }

  return (
    <>
      <Spacer />
  
      <Grid.Container gap={2} justify="flex-end" alignItems="center">
        {isWorkspaceAdmin && (
          <Grid>
            <Button auto onClick={onInviteTeamClick}>Invite Team</Button>
          </Grid>
        )}
        <Grid>
          <Button type="secondary" onClick={onNewBoarClick}>New Board</Button>
        </Grid>
      </Grid.Container>

      {content}

      {workspaceDoc && <InviteModal doc={workspaceDoc} {...bindingsInvite} />}
    </>
  )
}

const TabContent = ({ yourBoardsSnaphot, boardsSnapshot, sharedShanpshot, workspaceDoc }) => {
  const tab = useQueryTab()

  switch (tab) {
    case yourWorkspaceBoards: return <WorkspaceBoards snapshot={yourBoardsSnaphot} workspaceDoc={workspaceDoc} />
    case publicBoards: return <WorkspaceBoards snapshot={boardsSnapshot} workspaceDoc={workspaceDoc} />
    case sharedBoards: return <SharedBoards snapshot={sharedShanpshot} workspaceDoc={workspaceDoc} />
    default: return null
  }
}

const AdminWorkspaceHome = ({ workspaceDoc }) => {
  const [yourWorkspaceBoardsQuerySnapshot, loadingYourBoards] = useYourWorkspaceBoardsCollection()
  const [workspaceBoardsQuerySnapshot, loadingAllBoards] = useWorkspaceBoardsCollection([['visibility', '==', 'public']])

  if (loadingYourBoards || loadingAllBoards) return null

  return (
    <>
      <Tabs>
        <Tabs.Item label="Your boards" value={yourWorkspaceBoards} />
        <Tabs.Item label="Public boards" value={publicBoards} />
      </Tabs>

      <TabContent
        yourBoardsSnaphot={yourWorkspaceBoardsQuerySnapshot}
        boardsSnapshot={workspaceBoardsQuerySnapshot}

        workspaceDoc={workspaceDoc}
      />
    </>
  )
}

const PersonalWorkspaceHome = ({ workspaceDoc }) => {
  const [yourWorkspaceBoardsQuerySnapshot, loadingYourBoards] = useYourWorkspaceBoardsCollection()
  const [sharedRetrosQuerySnapshot, loadingShared] = userSharedRetrosCollection()

  if (loadingYourBoards || loadingShared) return null

  return (
    <>
      <Tabs>
        <Tabs.Item label="Your boards" value={yourWorkspaceBoards} />
        <Tabs.Item label="Shared boards" value={sharedBoards} />
      </Tabs>

      <TabContent
        yourBoardsSnaphot={yourWorkspaceBoardsQuerySnapshot}
        sharedShanpshot={sharedRetrosQuerySnapshot}

        workspaceDoc={workspaceDoc}
      />
    </>
  )
}

const MemberWorkspaceHome = ({ workspaceDoc }) => {
  const [yourWorkspaceBoardsQuerySnapshot, loadingYourBoards] = useYourWorkspaceBoardsCollection()
  const [workspaceBoardsQuerySnapshot, loadingAllBoards] = useWorkspaceBoardsCollection([['visibility', '==', 'public']])

  if (loadingYourBoards || loadingAllBoards) return null

  return (
    <>
      <Tabs>
        <Tabs.Item label="Your boards" value={yourWorkspaceBoards} />
        <Tabs.Item label="Public boards" value={publicBoards} />
      </Tabs>

      <TabContent
        yourBoardsSnaphot={yourWorkspaceBoardsQuerySnapshot}
        boardsSnapshot={workspaceBoardsQuerySnapshot}

        workspaceDoc={workspaceDoc}
      />
    </>
  )
}

export default function HomePage() {
  const [workspaceDoc, loading, error] = useCurrentWorkspaceDocument()
  const isWorkspaceAdmin = useIsDocAdmin(workspaceDoc)
  const isPersonalWorkspace = useIsPersonalWorkspace()

  const [showTeamAnnoucement, setShowTeamAnnoucement] = useLocalStorage('team-workspaces-announcement', true)

  if (loading || error) return null

  return (
    <>
      <Head>
        <title>Backdated</title>
      </Head>

      {showTeamAnnoucement && isPersonalWorkspace && (
        <Announcement
          title="Team workspaces are here! ✨"
          description="We're adding support for team workspaces under one user account. This makes it easier to keep your personal retro boards in a separate workspace from your company's workspace or to collaborate with multiple companies without logging in and out."
          onClose={() => setShowTeamAnnoucement(false)}
        />
      )}

      {isPersonalWorkspace ? (
        <PersonalWorkspaceHome workspaceDoc={workspaceDoc} />
      ) : (
        isWorkspaceAdmin ? (
          <AdminWorkspaceHome workspaceDoc={workspaceDoc} />
        ) : (
          <MemberWorkspaceHome workspaceDoc={workspaceDoc} />
        )
      )}
    </>
  )
}
