import { useRouter } from 'next/router'
import { startsWith, endsWith } from 'lodash'

import NextLink from 'next/link'
import { Link as GeistLink } from '@geist-ui/react'

export default function Link({ href, back, children, ...rest }) {
  const router = useRouter()
  const path = window?.location?.pathname || href

  const relativeHref = startsWith(href, '~/')
  const appHref = relativeHref ? href.replace('~/', endsWith(path, '/') ? path : `${path}/`) : href

  const onClick = event => {
    if (back) {
      event.preventDefault()
      event.stopPropagation()

      router.back()
    }
  }

  return (
    <NextLink href={appHref}>
      <GeistLink href={appHref} onClick={onClick} {...rest}>{children}</GeistLink>
    </NextLink>
  )
}

Link.propTypes = {
  ...NextLink.propTypes,
  ...GeistLink.propTypes,
}
